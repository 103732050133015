.contact {
  background-image: url('../img/contact.jpg');
  @include cover;
  height: 100vh;
  position: relative;
  padding-top: 120px;
  box-sizing: border-box;
  @include r(1065) {
    padding: 120px 70px 0; }
  @include r(767) {
    min-height: calc(100vh - 100px);
    height: 100%;
    padding: 0 20px 0; }
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(37, 57, 142, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .container {
    position: relative;
    z-index: 2; }
  h2 {
    font-size: 80px;
    color: #fff;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: right;
    @include rh(800) {
      margin-bottom: 20px; }
    @include r(1600) {
      font-size: 50px; }
    @include r(767) {
      font-size: 36px;
      margin-bottom: 30px;
      @include rh(700) {
        margin-bottom: 5px;
        font-size: 30px; } } }
  h3 {
    font-size: 36px;
    color: #fff;
    text-transform: uppercase;
    text-align: right;
    margin-bottom: 30px;
    @include r(1600) {
      font-size: 30px; }
    @include r(767) {
      @include rh(650) {
        margin-bottom: 10px; } }
    @include r(374) {
      margin-bottom: 10px;
      font-size: 23px; } }
  li {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    text-align: right;
    margin-bottom: 25px;
    a {
      font-size: inherit;
      color: inherit;
      &:hover {
        text-decoration: underline; } }
    @include r(1600) {
      font-size: 18px;
      font-weight: 500;
      color: #fff; }
    @include r(767) {
      @include rh(650) {
        font-size: 15px;
        margin-bottom: 15px; } }
    @include r(374) {
      font-size: 16px;
      margin-bottom: 15px; } }
  p {
    text-align: right;
    font-size: 24px;
    color: #fff;
    font-weight: normal;
    max-width: 845px;
    width: 100%;
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%);
    margin-bottom: 95px;
    margin-top: 110px;
    @include rh(800) {
      margin-top: 50px; }
    @include r(1600) {
      font-size: 18px;
      max-width: 725px; }
    @include r(767) {
      margin-top: 40px;
      margin-bottom: 130px;
      @include rh(790) {
        margin-bottom: 30px;
        margin-top: 0px;
        font-size: 16px; } }
    @include r(374) {
      margin-top: 0; } }
  &__footer {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 15px;
    @include r(1065) {
      bottom: 80px; }
    @include r(767) {
      flex-direction: column-reverse; }
    a, b {
      font-size: 18px;
      font-weight: 400;
      color: rgba(#fff, 0.8);
      @include r(767) {
        font-size: 14px; }
      @include r(374) {
        font-size: 12px; } }

    a:hover {
      text-decoration: underline; }
    b {
      @include r(767) {
        margin-bottom: 5px; } } } }
