.map {
  background: #25398E;
  height: 100vh;
  padding-top: 195px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  @include rh(930) {
    padding-top: 100px;
    padding-bottom: 280px; }
  @include r(1065) {
    padding-top: 150px; }
  @include r(767) {
    @include rh(700) {
      padding-top: 130px; } }
  @include r(374) {
    padding-top: 110px; }
  h2 {
    font-size: 80px;
    color: #fff;
    text-align: right;
    position: relative;
    left: 100%;
    transform: translate(-100%);
    line-height: 1.2;
    max-width: 700px;
    margin-bottom: 75px;
    text-transform: uppercase;
    @include r(1600) {
      font-size: 50px;
      margin-bottom: 45px; }
    @include r(1065) {
      font-size: 50px;
      margin-bottom: 110px;
      @include rh(1000) {
        margin-bottom: 15px; } }
    @include r(767) {
      font-size: 36px;
      margin-bottom: 100px;
      @include rh(740) {
        margin-bottom: 50px; }
      @include rh(585) {
        margin-bottom: 20px; } }
    @include r(374) {
      font-size: 30px; } }
  &__row {
    display: flex;
    justify-content: space-between;
    @include r(1065) {
      flex-wrap: wrap;
      padding-left: 30px;
      @include rh(1000) {
        flex-wrap: nowrap; } }
    @include r(767) {
      flex-wrap: wrap; } }
  ul {
    @include r(1065) {
      width: 50%;
      margin-bottom: 70px;
      @include center-column; }
    @include r(767) {
      margin-bottom: 0;
      display: block; } }
  li {
    font-size: 40px;
    line-height: 1.3;
    color: #fff;
    display: flex;
    align-items: center;
    @include r(1600) {
      font-size: 25px; }
    @include r(1065) {
      width: 170px; }
    @include r(767) {
      font-size: 20px;
      width: auto; }
    &::before {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #fff;
      margin-right: 15px;
      @include r(1600) {
        width: 8px;
        height: 8px; } } }

  &__img {
    position: absolute;
    left: 50%;
    width: 1600px;
    transform: translate(-50%) !important;
    bottom: -300px;
    opacity: 0;
    transition: all 1.2s !important;
    &.top {
      bottom: 0px;
      opacity: 1 !important; }
    @include r(1500) {
      width: 1200px; }
    @include r(1065) {
      width: 1065px;
      &.top {
        bottom: 0px; }
      @include rh(1000) {
        &.top {
          bottom: -40px; } } }
    @include r(800) {
      width: 800px; }
    @include r(767) {
      width: 600px;
      &.top {
        bottom: 0px; } }
    @include r(500) {
      width: 500px;
      left: 40%; }
    @include r(374) {
      left: 30%;
      &.top {
        bottom: 0; } }
    .map-img {
      width: 100%;
      @include r(1065) {
        display: none; }
      &-mobile {
        display: none;
        width: 100%;
        @include r(1065) {
          display: block; } } }
    .marker-img {
      position: absolute;
      right: 16.5%;
      top: 10%;
      width: 42.5%;
      animation: merker 2s ease infinite;
      @include r(1065) {
        right: 7.5%;
        top: 6%;
        width: 57.5%; }
      // +r(1065)
 } } }      //    width: 685px


@keyframes merker {
  0% {
    transform: translateY(0); }
  70% {
    transform: translateY(-3px); }
  100% {
    transform: translateY(0); } }
