.icon-product_1 {
  width: 1.13em;
  height: 1em;
  fill: initial;
}
.icon-product_2 {
  width: 1.6em;
  height: 1em;
  fill: initial;
}
.icon-product_3 {
  width: 1.95em;
  height: 1em;
  fill: initial;
}
.icon-product_4 {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
.icon-product_5 {
  width: 1.05em;
  height: 1em;
  fill: initial;
}
.icon-product_6 {
  width: 1.39em;
  height: 1em;
  fill: initial;
}
