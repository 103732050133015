.about {
  height: 100vh;
  overflow: hidden;
  padding-top: 120px;
  position: relative;
  box-sizing: border-box;
  border-top: 3px solid #fff;
  @include rh(800) {
    padding-top: 80px; }
  @include r(1065) {
    padding-top: 0; }
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: -1;
    div {
      width: 100%;
      height: 100%;
      background: rgba(37, 57, 142, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 6;
      display: block; }
    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      z-index: -1;
      @include r(1065) {
        height: 100%; } } }

  &__content {
    position: relative;
    z-index: 7;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    @include rh(600) {
      justify-content: flex-start;
      padding-top: 30px; }
    @include r(1065) {
      min-height: calc(100vh - 110px);
      padding-top: 40px;
      // +rh(800)
 }      //   justify-content: flex-start
    @include r(767) {
      min-height: calc(100vh - 110px);
      justify-content: center; }
    h2 {
      font-size: 80px;
      color: #fff;
      text-align: right;
      text-transform: uppercase;
      margin-bottom: 140px;
      @include r(1600) {
        font-size: 50px; }
      @include rh(800) {
        margin-bottom: 50px; }
      @include r(1065) {
        font-size: 60px; }
      @include r(767) {
        margin-bottom: 40px;
        font-size: 36px; }
      @include r(374) {
        margin-bottom: 20px; } } }
  .swiper-pagination {
    bottom: 60px;
    left: 50%;
    transform: translate(-50%);
    @include r(1065) {
      bottom: 80px; }
    @include r(767) {
      @include rhmin(750) {
        bottom: 140px; } } }
  .swiper-container {
    padding-bottom: 120px;
    width: 100%;
    @include r(767) {
      padding-bottom: 30px; } }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    background: #fff;
    opacity: 1;
    border-radius: 50px;
    @include tr;
    margin: 0 12px !important;
    &-active {
      width: 40px; } }
  &__slide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      font-size: 40px;
      color: #ffffff;
      text-align: justify;
      max-width: 1140px;
      display: block;
      @include r(1600) {
        font-size: 30px; }
      @include r(767) {
        font-size: 20px;
        text-align: left; }
      @include r(374) {
        font-size: 18px; } } } }
