.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
  @include r(1200) {
    padding: 15px; }
  &__logo {
    height: 90px;
    width: 295px;
    cursor: pointer;
    @include r(1600) {
      width: 147px;
      height: 45px; }
    @include r(1065) {
      height: 90px;
      width: 295px; }
    @include r(767) {
      width: 203px;
      height: 62px; }
    img {
      height: 100%;
      width: 100%; } }
  nav {
    flex: 1;
    @include r(1065) {
      display: none; } }
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  a {
    font-size: 18px;
    color: #25398E;
    text-transform: uppercase;
    margin-left: 100px;
    display: block;
    border-bottom: 4px solid transparent;
    @include r(1600) {
      font-size: 16px; }
    &:hover {
      border-color: rgba(#2F67BB, 0.6); }
    @include r(1400) {
      margin-left: 40px; } }
  li.active a {
    border-bottom-color: #2F67BB; }
  .menu-open {
    position: relative;
    width: 60px;
    height: 50px;
    display: none;
    @include r(1065) {
      display: block; }
    @include r(767) {
      width: 40px;
      height: 26px; }
    span {
      width: 57px;
      height: 6px;
      margin-top: calc(50% - 7px);
      background: #25398E;
      display: block;
      border-radius: 3px;
      @include tr;
      @include r(767) {
        width: 40px;
        height: 4px;
        margin-top: calc(50% - 9px); }
      &:before, &:after {
        content: '';
        width: 57px;
        height: 6px;
        background: #25398E;
        position: absolute;
        left: 0;
        border-radius: 3px;
        @include tr;
        @include r(767) {
          width: 40px;
          height: 4px; } }
      &:before {
        top: 0; }
      &:after {
        bottom: 0px; } }


    &.active {
      span {
        width: 0;
        &::before {
          transform: rotate(135deg);
          top: 20px;
          @include r(767) {
            top: 10px; } }
        &::after {
          transform: rotate(-135deg);
          top: 20px;
          @include r(767) {
            top: 10px; } } } } } }



.menu {
  width: 100%;
  height: 100%;
  background: #25398E;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 180px 0 15px;
  overflow: auto;
  display: none;
  @include r(767) {
    padding: 115px 40px 20px; }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 30px;
    @include rmin(768) {
      @include rh(990) {
        display: block; } }
    @include r(767) {
      @include rh(550) {
        display: block; } } }
  h2 {
    font-size: 60px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 90px;
    text-align: center;
    display: block;
    @include r(767) {
      font-size: 36px;
      margin-bottom: 45px;
      text-align: left; } }
  li {
    display: flex;
    justify-content: center;
    @include r(767) {
      display: block; } }
  a {
    font-size: 30px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    position: relative;
    @include r(767) {
      font-size: 18px !important;
      margin-bottom: 25px;
      justify-content: flex-start; }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      background: #fff;
      margin-right: 15px;
      @include r(767) {
        width: 5px;
        height: 5px;
        margin-right: 8px; } } }
  li.active a {
    position: relative;
    &::after {
      content: '';
      width: 94%;
      height: 18px;
      background: #2D4EA6;
      position: absolute;
      bottom: -5px;
      right: 0;
      z-index: -1; } } }
