.product {
  // +r(1065)
  //   padding-top: 160px
  // +r(767)
  //   padding-top: 90px
  h2 {
    font-size: 80px;
    color: #ffffff;
    display: block;
    padding: 23px 10px;
    background: #25398E;
    text-transform: uppercase;
    text-align: center;
    padding-top: 140px;
    @include r(1600) {
      font-size: 45px;
      padding: 15px 10px;
      padding-top: 90px; }
    @include r(1065) {
      padding-top: 130px; }
    @include r(767) {
      font-size: 36px;
      padding-top: 110px;
 }      // padding: 28px 15px
    @include r(374) {
      font-size: 30px; } }
  &--materials {
    .swiper-container {
      @include r(1065) {
        height: auto; } }
    p {
      max-width: 280px;
      @include r(1065) {
        max-width: 100%; } }
    .swiper-button-prev, .swiper-button-next {
      display: none; } }
  .swiper-container {
    height: calc(100vh - 260px);
    // width: calc(100% + 1px)
    @include r(1600) {
      height: calc(100vh - 155px); }
    @include r(1065) {
      height: calc(100vh - 200px);
      .swiper-wrapper {
        flex-wrap: wrap; }
      // +rh(1050)
 }      //   height: 780px
    @include r(767) {
      height: calc(100vh - 150px);
      @include rh(640) {
        height: calc(100vh - 160px); }
      .swiper-wrapper {
        flex-wrap: nowrap; } } }

  .swiper-slide {
    position: relative;
    transition: all 0.7s;
    top: 400px;
    opacity: 0;
    visibility: hidden;
    &.top {
      top: 0;
      opacity: 1;
      visibility: visible; }

    @include r(1065) {
      height: calc(100% / 3); }
    @include r(767) {
      height: auto; }
    &:nth-child(1) {
      transition-delay: 0.3s; }
    &:nth-child(2) {
      transition-delay: 0.4s; }
    &:nth-child(3) {
      transition-delay: 0.5s; }
    &:nth-child(4) {
      transition-delay: 0.6s; }
    &:nth-child(5) {
      transition-delay: 0.7s; }
    &:nth-child(6) {
      transition-delay: 0.8s; } }
  .swiper-button-next, .swiper-button-prev {
    background: none;
    width: auto;
    height: auto;
    top: auto;
    bottom: 70px;
    @include r(1600) {
      bottom: 40px; }
    @include r(1024) {
      display: none; }
    span {
      font-size: 30px;
      color: #fff;
      @include r(767) {
        font-size: 20px;
        color: #fff; } }
    &::after {
      display: none; }
    @include r(767) {
      &.blue {
        span {
          color: #25398E; } } } }
  .swiper-button-next {
    right: 20%;
    @include r(767) {
      bottom: 150px;
      @include rhmin(750) {
        bottom: 200px; } } }
  .swiper-button-prev {
    left: 20%;
    @include r(767) {
      bottom: 110px;
      @include rhmin(750) {
        bottom: 150px; } }
    span {
      color: #C4C4C4;
      @include r(767) {
        color: #fff; } } }
  .swiper-button-disabled {
    display: none; }
  .swiper-pagination {
    bottom: 100px;
    left: 50%;
    transform: translate(-50%);
    display: none;
    @include r(767) {
      display: block;
      @include rhmin(750) {
        bottom: 140px; } } }
  .swiper-pagination-bullet {
    width: 19px;
    height: 19px;
    background: #fff;
    opacity: 1;
    border-radius: 50px;
    @include tr;
    margin: 0 12px !important;
    &-active {
      width: 40px; } } }


