.intro {
  height: 100vh;
  overflow: hidden;
  padding-top: 120px;
  position: relative;
  @include r(1065) {
    height: 100%;
    padding: 90px 50px 0; }
  @include r(767) {
    padding: 90px 0px 0; }
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: -1;
    div {
      width: 100%;
      height: 100%;
      background: rgba(37, 57, 142, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      display: block; }
    video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      z-index: -1;
      @include r(1065) {
        left: 60%; }
      @include r(767) {
        top: auto;
        left: -350px;
        bottom: -90px;
        transform: none; } } }
  &__title {
    position: relative;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    @include rh(650) {
      justify-content: flex-start;
      padding-top: 30px; }
    @include r(1065) {
      justify-content: center;
      min-height: calc(100vh - 120px);
      height: 100%;
      @include rh(600) {
        justify-content: flex-start;
        padding-top: 90px;
        padding-bottom: 20px; } }


    h1 {
      font-size: 100px;
      color: #fff;
      margin-bottom: 10px;
      line-height: 0.72;
      text-transform: uppercase;
      span {
        transition: all 1.3s;
        position: relative;
        top: 0px;
        &.active {
          top: -20px; } }
      @include r(1065) {
        font-size: 80px; }
      @include r(767) {
        font-size: 36px; }
      .load {
        transition: all 1.3s;
        position: relative;
        top: 0;
        &.active {
          top: 20px; } }
      b {
        opacity: 0.5;
        font-size: 100px;
        color: #fff;
        display: block;
        text-transform: uppercase;
        transform: matrix(1, 0, 0, -1, 0, 0);
        background: linear-gradient(0deg, rgba(#fff, 0.4) 33%, rgba(#fff, 0.1) 56%, rgba(#fff, 0));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include r(1065) {
          font-size: 80px; }
        @include r(767) {
          font-size: 36px; } } }

    h3 {
      font-size: 50px;
      color: #fff;
      text-align: right;
      max-width: 640px;
      width: 100%;
      @include r(1600) {
        font-size: 35px;
        max-width: 500px; }
      @include r(767) {
        font-size: 24px;
        margin-bottom: 200px; } } } }
