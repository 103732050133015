html, body {
  font-family: 'Montserrat';
  box-sizing: border-box;
  position: relative;
  font-weight: bold;
  // +r(1065)
  //   overflow: visible !important
 }  //   height: auto !important
.container {
  box-sizing: border-box;
  @include rmin(1200) {
    max-width: 1200px !important; } }
a {
  text-decoration: none;
  transition: all 0.3s; }

.mobile-rotate {
  display: none; }
@media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
  body {
    .mobile-rotate {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: #25398e;
      z-index: 99;
      @include center;
      p {
        color: #ffffff;
        font-size: 28px; }
      img {
        width: 100px;
        height: 100px;
        margin-right: 30px; } } } }




// +r(767)
// 	.hidden-xs
// 		display: none !important
// @media only screen and (min-width: 768px) and (max-width: 1024px)
// 	.hidden-sm
// 		display: none !important
// +rmin(1024)
// 	.hidden-md
// 		display: none !important
// .section
//   +r(1065)
//     position: static !important
// .pp-scrollable
//   overflow: hidden
.swiper-screen {
  height: 100vh;
  overflow: hidden; }
.section5 {
  transition: all 0.3s; }
.section5, .section6, .section7 {
  opacity: 0;
  transform: none !important;
  visibility: hidden;
  // +r(1065)
  //   opacity: 1
  //   visibility: visible
  &.active {
    opacity: 1;
    visibility: visible; } }

.btn-next {
  transform: rotate(90deg);
  transform-origin: right top;
  position: fixed;
  bottom: -20px;
  right: 0px;
  width: 200px;
  color: #fff;
  font-size: 18px;
  z-index: 8;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include tr;
  @include r(1065) {
    display: none;
    right: 10px;
    &.active, &.up {
      display: flex; } }
  &.active {
    @include r(767) {
      font-size: 15px;
      i {
        left: 30px; } } }


  i {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='89' viewBox='0 0 16 89' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.29289 88.7071C7.68341 89.0976 8.31658 89.0976 8.7071 88.7071L15.0711 82.3431C15.4616 81.9526 15.4616 81.3195 15.0711 80.9289C14.6805 80.5384 14.0474 80.5384 13.6569 80.9289L8 86.5858L2.34314 80.9289C1.95262 80.5384 1.31945 80.5384 0.928929 80.9289C0.538404 81.3195 0.538404 81.9526 0.928929 82.3431L7.29289 88.7071ZM7 -4.37114e-08L7 88L9 88L9 4.37114e-08L7 -4.37114e-08Z' fill='white'/%3E%3C/svg%3E%0A");
    width: 16px;
    height: 88px;
    display: block;
    transform: rotate(-90deg);
    position: relative;
    left: 45px;
    @include r(1065) {
      height: 43px;
      background-position: 0 100%; } }

  &.down {
    b {
      opacity: 0; } }
  &.up {
    bottom: 20px;
    @include r(1065) {
      bottom: 70px !important; }
    @include r(767) {
      right: 0; }
    b {
      opacity: 1;
      position: relative;
      left: 100px;
      @include r(1065) {
        left: 80px; } }
    span {
      opacity: 0; }
    i {
      transform: rotate(90deg); } } }






.product-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  pointer-events: auto;
  @include cover;
  cursor: pointer;
  padding: 30px;
  @include rmin(1065) {
    opacity: 1 !important;
    transform: none !important; }
  @include r(767) {
    display: block;
    padding: 80px 40px 150px;
    @include rh(700) {
      padding-top: 10px; } }
  @include rmin(768) {
    &:hover {
      &::before {
        opacity: 1; }
      .product-card__bg {
        opacity: 0; }
      .product-card__img--decor {
        background: rgba(255, 255, 255, 0.2) !important; }
      p {
        color: #fff !important; } } }
  &::before {
    content: '';
    background: rgba(37, 57, 142, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    @include tr;
    opacity: 0;
    @include r(767) {
      opacity: 1; } }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include tr;
    @include r(767) {
      display: none; } }
  &__img {
    position: relative;
    z-index: 4;
    width: 100%;
    max-width: 250px;
    @include r(1065) {
      left: -30px;
      bottom: -20px;
      max-width: 100%;
      transform: scale(0.8); }
    &--decor {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      @include tr;
      @include r(767) {
        background: #C4C4C4 !important; } }
    .product_img {
      position: absolute;
      left: -25px;
      top: 4px; } }

  p {
    font-size: 50px;
    position: relative;
    z-index: 4;
    display: block;
    max-width: 250px;
    min-height: 250px;
    width: 100%;
    padding-top: 40px;
    @include tr;
    @include r(1600) {
      font-size: 30px; }
    @include r(1065) {
      min-height: auto;
      max-width: 100%;
      font-size: 25px;
      padding-top: 25px;
      @include rh(800) {
        min-height: 140px; } }
    @include r(767) {
      min-height: auto;
      color: #fff !important; }
    @include r(374) {
      font-size: 22px; }
    &.white {
      color: #fff; }
    &.blue {
      color: #25398E; } } }






.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  @include center-column;
  background: #25398e;
  img {
    width: 300px; }
  &__line {
    width: 300px;
    height: 7px;
    background: rgba(153, 153, 153, 1);
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    div {
      width: 30%;
      position: absolute;
      left: -30%;
      top: 0;
      height: 100%;
      background: #fff;
      animation: rotate 3s linear infinite; } } }


@keyframes rotate {
  from {
    left: -30%; }
  to {
    left: 100%; } }
